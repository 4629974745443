<template>
	<panel :show="show" @close="close">
		<template #title>
			Reassign Call
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-reassign.svg"
					class="panel-illustration"
				/>
				<div
					v-if="isComplete"
					class="bg-orange-100 border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md mb-8 font-roboto"
					role="alert"
				>
					<div class="flex">
						<div class="py-1">
							<svg
								class="fill-current h-6 w-6 text-orange-500 mr-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path
									d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
								/>
							</svg>
						</div>
						<div>
							<p>
								<strong>Reassigning Completed Call</strong>
							</p>
							<p class="text-sm">
								The call will be reassigned and will appear in
								the provider's Clarus app inbox, but the
								provider will not be notified.
							</p>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="provider" class="form-label">
						Select provider to reassign call.
					</label>
					<div class="relative">
						<select
							id="provider"
							v-model="providerId"
							name="provider"
							placeholder="Select a provider"
							class="form-input"
							:class="[
								providerId ? 'text-gray-700' : 'text-gray-500',
							]"
						>
							<option :value="null" disabled
								>Select a provider</option
							>
							<option
								v-for="provider in providers"
								:key="provider.id"
								:value="provider.id"
							>
								{{ provider.full_name }}
							</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							></font-awesome-icon>
						</div>
					</div>
				</div>

				<label v-if="!isComplete" class="font-roboto mb-5">
					<input
						v-model="restartNotification"
						class="mr-2"
						type="checkbox"
					/>
					<span class="text-sm">
						Restart Notifications
					</span>
				</label>

				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						:disabled="busy"
						@keydown.tab="onLastElementTab"
						@click.prevent="onCallReassign"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							v-if="busy"
							class="btn-icon fa-spin mr-2"
							:icon="['far', 'circle-notch']"
						/>
						<font-awesome-icon
							v-else
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
						<span class="btn-label">Reassign</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the current call is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			if (!this.call) {
				return
			}

			return !!this.call.completed_at
		},

		...mapGetters({
			providers: 'providers/internal',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the reassign call click event.
		 *
		 * @return {void}
		 */
		async onCallReassign() {
			if (!this.providerId) {
				return
			}

			this.busy = true

			try {
				await this.$api.calls().reassign({
					sid: this.call.sid,
					providerId: this.providerId,
					urgentForward: this.isComplete
						? false
						: this.restartNotification,
				})

				await this.getCall(this.call.sid)

				this.$alert.success('calls.assign.success')
			} catch (e) {
				this.$alert.error('calls.assign.error')
			}

			this.busy = false

			this.close()
		},

		...mapActions({
			getCall: 'calls/find',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ReassignCallPanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The call to be reassigned.
		 *
		 * @type {Object}
		 */
		call: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the call for changes.
		 *
		 * @type {Object}
		 */
		call() {
			if (this.call && this.call.provider) {
				this.providerId = this.call.provider.id
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			providerId: null,
			restartNotification: true,
			selectedProvider: null,
		}
	},
}
</script>
