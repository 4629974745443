/**
 * Constant representing an editable field that is currently saving.
 */
const SAVING = 'SAVING'

/**
 * Constant representing an editable field that has unsaved changed.
 */
const UNSAVED = 'UNSAVED'

/**
 * Constant representing an editable field that is being edited but is unchanged.
 */
const UNCHANGED = 'UNCHANGED'

/**
 * Editable text field manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Editable {
	/**
	 * Create a new Editable instance.
	 */
	constructor() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false
	}

	/**
	 * Get the editable fields status.
	 *
	 * @return {String}
	 */
	get status() {
		if (!this.editing) {
			return ''
		}

		if (this.saving) {
			return SAVING
		}

		if (this.changed) {
			return UNSAVED
		}

		return UNCHANGED
	}

	/**
	 * Update the current & original value and mark the field as being edited.
	 *
	 * @param {String} value
	 * @return {Editable}
	 */
	edit(value) {
		this.value = value || ''
		this.original = value || ''
		this.editing = true

		return this
	}

	/**
	 * Check for changes on the keyup event.
	 *
	 * @param {KeyboardEvent} event
	 * @return {Editable}
	 */
	onKeyUp(event) {
		this.changed = this.original !== event.target.innerText

		return this
	}

	/**
	 * Reset the editable field back to its original state.
	 *
	 * @return {Editable}
	 */
	reset() {
		this.value = ''
		this.original = ''
		this.changed = false
		this.editing = false
		this.saving = false

		return this
	}

	/**
	 * Update the value from an event on the editable element.
	 *
	 * @param {KeyboardEvent|FocusEvent} event
	 * @return {Editable}
	 */
	update(event) {
		this.value = event.target.innerText.trim()

		return this
	}
}
